/* eslint-disable no-constant-binary-expression */
import React, { useEffect, useState } from 'react';
import AltaReclamosPaso1 from './AltaReclamosPaso1';
import AltaReclamosPaso2 from './AltaReclamosPaso2';
import AltaReclamosPaso3 from './AltaReclamosPaso3';
import AltaReclamosPaso4 from './AltaReclamosPaso4';
import AltaReclamosPaso5 from './AltaReclamosPaso5';
import { useParams, useNavigate } from 'react-router-dom';
import {  Button, CircularProgress, Step, StepLabel, Stepper } from '@mui/material';
import Toast from '../../componentes/Toast';
// import FooterImage from '../../img/v2/FOOTER.jpg';
import useTicketStore from '../../stores/ticket.store';
import useUserStore from '../../stores/user.store';
import useAltaReclamoStore from '../../stores/altareclamo.store';
import axios from 'axios';
import Hero from '../../componentes/hero.jsx';
import ReCAPTCHA from "@matt-block/react-recaptcha-v2";
import Footer from '../../componentes/footer.jsx';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
const steps = ['Prestación', 'Dirección', 'Problema', 'Datos personales', 'Recibo'];


const Altareclamos = () => {
  let { step } = useParams();
  step = parseInt(step);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {setTicket, ticket, reCAPTCHA, prestacion, direccion, description, answers, nonce, sent, questionnaire, reset, setReCAPTCHA,} = useTicketStore();
  const persona = useUserStore(state => state.user);

  const body = { persona, prestacion, direccion, description, answers, nonce, questionnaire, reCAPTCHA, sent };

  const [alertId, setAlertId] = useState(0);
  const [message, setMessage] = useState('');
  const [severity, setSeveritySnackbar] = useState('success');

  const setMessageSnackbar = (messageSnackbar, severitySnackbar) => {
    setMessage(messageSnackbar);
    severity && setSeveritySnackbar(severitySnackbar);
    setAlertId((id) => id + 1);
  };

  const {prevStep, nextStep, setStep} = useAltaReclamoStore();
  const options = {setMessageSnackbar};

  const crearTicket = async () => {
    try {
      setLoading(true);

      // completo el captcha?
      if (!reCAPTCHA) {
        throw new Error('Debe completar el reCAPTCHA antes de enviar el reclamo');
      }

      let url = new URL(`${api}/ticket`);
      axios.post(url, body)
        .then(ticketNuevo => {
          if (ticketNuevo?.data?.error) {
            setLoading(false);
            setMessageSnackbar(ticketNuevo?.data?.message, 'error');
          } else {
            setTicket(ticketNuevo?.data?.tic_identificador);
            setLoading(false);
            setMessageSnackbar('Solicitud ingresada con éxito', 'success');
          }
        })
        .catch((err) => {
          setLoading(false);
          setMessageSnackbar(`No se pudo enviar la solicitud. Error: ${err.message}`, 'error');
        });
    } catch (err) {
      setLoading(false);
      setMessageSnackbar(err.message, 'error');
    }
  }

  useEffect(() => {
    setStep(step);
  }, [step]);

  const handleCancel = async () => {
    await reset();
    navigate('/');
  }

  // const captchaRef = forwardRef(false);
  const onChange = async (value) => {
    const newValue = value ? true : false;
    if(newValue) {
      setReCAPTCHA(true);
    } else {
      setReCAPTCHA(false);
    }
  };

  return (
    <div className="flex flex-col justify-between h-[100vh] w-full">
      <Hero />

      <section className="flex-1 ml-2 mr-2 lg:ml-10 lg:mr-10 mt-2 mb-2 lg:mt-5 lg:mb-5">
        {!sent &&
          <Stepper activeStep={step - 1} className='mb-5 overflow-scroll'>
            {steps.map((label, ix) => (
              <Step key={ix}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        }

        {step == 1 && <AltaReclamosPaso1 options={options} /> /* Determinar la prestacion */}
        {step == 2 && <AltaReclamosPaso2 options={options} /> /* Direccion */}
        {step == 3 && <AltaReclamosPaso3 options={options} /> /* Notas de caso, encuesta */}
        {step == 4 && <AltaReclamosPaso4 options={options} /> /* Datos personales */}
        {step == 5 && <AltaReclamosPaso5 options={options} /> /* Datos confirmación */}
      </section >

      <div style={{paddingTop: '10px', marginLeft: '40px', display: (!sent && step == 5 ? 'block' : 'none')}}>
          <ReCAPTCHA
            // ref={captchaRef}
            // eslint-disable-next-line no-undef
            siteKey={process.env?.REACT_APP_CAPTCHA_KEY}
            onSuccess={onChange}
            hl="es-419"
          />
      </div>

      {loading &&
        <div style={{ textAlign: "center", width: "100%" }}><CircularProgress style={{ margin: "auto" }} /></div>}

      <div className="flex justify-end ml-10 mr-10 gap-5 mb-10">
        {!sent &&
          <Button variant="outlined" className="text-gray-50 border-gray-50" onClick={handleCancel}>Cancelar</Button>}

        {step > 1 && !sent &&
          <Button variant="contained" onClick={() => {
            if(prevStep(body)) navigate('/reclamos/alta/' + (step - 1));
          }} type="submit" className="text-gray-50 border-gray-50">Atrás</Button>}

        {step < 5 &&
          <Button variant="contained" onClick={() => {
            if(nextStep(body)) navigate('/reclamos/alta/' + (step + 1));
          }} type="submit" className="text-gray-50 bg-[rgb(11,59,108)]">Siguiente</Button>}

        {step == 5 && !sent &&
          <Button variant="contained" onClick={() => crearTicket(ticket)} type="submit" className="text-gray-50 bg-[rgb(11,59,108)]" disabled={!reCAPTCHA}>Crear Reclamo</Button>}

        {sent &&
          <Button variant="outlined" className="text-gray-50 border-gray-50" onClick={handleCancel}>Ir al inicio</Button>}
      </div>

      {false && <>
        <p>Body</p>
        <pre>{JSON.stringify(body, null, 2)}</pre>
        </>}

      <Footer />

      <Toast message={message} severity={severity} alertId={alertId} />
    </div>
  );
};

export default Altareclamos;
