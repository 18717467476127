/* eslint-disable no-undef */
import React from 'react';
import { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

// value: Abracadabra
const BuscadorLugares = ({value, type, onChange, error, label, ...others}) => {
  const [open, setOpen] = useState(false);
  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(value || '');
  const [lugares, setLugares] = useState([]);

  async function loadLugares() { 
    try {
        if(loading || lugares?.length>0) {
          return;
        }
        setLoading(true);
        const api = process.env.NODE_ENV=='development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;
        let url = new URL(`${api}/places/${type}`);  
        const response = await fetch(url);
        const data = await response.json();
        setLugares(data);
        setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  useEffect(() => {loadLugares()}, []);

  /**
   * Buscar los lugares que coinciden con las 3 primeras letras
   * @param {*} busqueda 
   */
  async function load(busqueda) { 
    try {
      setSearchText(busqueda);
      if(busqueda=='') {
        setOptionsList([]);
        return;
      }
      if(lugares?.length>0) {
        const utterance = busqueda.toUpperCase();
        const data = lugares.filter((l) => l.nombre.toUpperCase().includes(utterance));
        setOptionsList(data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Vaciar la lista cuando se cierra el dropdown
  useEffect(() => {
    if (!open) {
      setOptionsList([]);
    }
  }, [open]);

  // Si cambia el nombre del lugar fuera del componente
  useEffect(() => {
    if (value!=searchText) {
      setSearchText(value);
      setOptionsList([]);
    }
  }, [value]);

  return (
        <Autocomplete
          {...others}
          inputValue={searchText}
          onInputChange={(event, newInputValue) => {
            load(newInputValue);
          }}

          value={value}
          onChange={(event, newValue) => {
            onChange(newValue);    
            setOptionsList([]);
            setOpen(false) // Borra la lista de opciones
          }}

          options={optionsList}
          getOptionLabel={(o) => o?.nombre || value}
          isOptionEqualToValue={(opt, v) => {
            return opt.nombre==v;
          }}
          noOptionsText={'Escriba el nombre a buscar'}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={()=> setOpen(false)}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              value={searchText}
              required
              error={error}
              label={label}
              InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                ),
              }}
            />
          )}
        />
    );
}

BuscadorLugares.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
};

export default BuscadorLugares;
