/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React from 'react';
import {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import BuscadorPrestacionesItem from './BuscadorPrestacionesItem';

const api = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

/*
 * @param {*} props
 * @return {object}
 */
const BuscadorPrestaciones = ({ utterance, onChange, onChangeUtterance, className }) => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState();
  const [dirty, setDirty] = useState(false);

  const search = () => {
    setDirty(false);
    setLoading(true);
    let url = new URL(`${api}/prestacion/buscar`);
    url.searchParams.set('search', utterance);
    axios.get(url)
      .then((res) => {
        setServices(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  // Busca las prestaciones a medida que se escribe
  useEffect(() => {    
    if (dirty && utterance.length>=3 && !loading) {
      search(utterance);
    }

    if (dirty && utterance=='' && !loading) {
      setServices([]);
    }
  }, [utterance, loading]);

  useEffect(() => {
    if (utterance.length>=3) {
      search(utterance);
    }
  }, []);

  // Salta a la prestacion elegida
  const handleSetPrestacion = (code) => () => {
    const prestacion = services.find((s) => s.code == code);
    onChange({...prestacion, utterance: utterance});
  }

  return (
    <div className={className + " flex flex-col"}>
      
      <div className="flex-1 w-full bg-white border-[rgb(0,166,203)] border rounded-lg flex items-center p-1">
        <input 
          placeholder="¿Con qué se relaciona tu reclamo?" 
          className="w-full lg:w-5/6 text-xl text-[rgb(11,59,108)] p-2 focus:outline-none" 
          value={utterance}
          onChange={(ev) => {
            onChangeUtterance(ev.target.value);
            setDirty(true);
          }}
        />
      </div>

      {services && 
      <div className="flex-1 mt-2 lg:mt-10 w-full flex flex-col gap-2">
        {services.length>0 && services.map((s, ix) => (
          <BuscadorPrestacionesItem key={ix} item={s} onClick={handleSetPrestacion}/>
        ))}
        {services.length==0 &&
        utterance.length>=3 && 
        <div>No podemos encontrar la prestación mas apropiada que se ajuste a tu descripción, haz click en &quot;inicia tu reclamo&quot; para elegirla de una lista</div>}
      </div>}
    </div>
  );
}

BuscadorPrestaciones.propTypes = {
  utterance: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeUtterance: PropTypes.func.isRequired,
  style: PropTypes.object,
};

export default BuscadorPrestaciones;
