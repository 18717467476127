import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { CircularProgress, Button } from '@mui/material';
import Mapa from './Mapa';
import DireccionDomicilio from './DireccionDomicilio';
import DireccionVilla from './DireccionVilla';
import DireccionPlaza from './DireccionPlaza';
import DireccionPlaya from './DireccionPlaya';
import DireccionCementerio from './DireccionCementerio';
import DireccionOrgPublico from './DireccionOrgPublico';
import DireccionColectivo from './DireccionColectivo';

// eslint-disable-next-line no-undef
const api = process.env.NODE_ENV == 'development' ? process.env.REACT_APP_API_URL_DEV : process.env.REACT_APP_API_URL_PROD;

const ZONIFICACION = {
    "Barrios": 1,
    "ZonasDeLuminarias": 2,
    "Playas": 3, // Zona Limpieza aurbana
    "BarridoManual": 4,
    "BarridoMecanico": 5,
    "RecoleccionDeResiduos": 6,
    "ResiduosNoHabituales": 7,
    "Delegacion": 8,
    "ZonaUrbana": 9, // Reservas forestales
};

/**
 * Recibe la prestacion y muestra el cuestionario
 * @param {*} value Valores actuales del cuestionario
 * @param {*} type Tipo de geolocalización
 * @param {*} onChange Evento cuando se registra una dirección
 * @return {React.ReactFragment} 
 */
const Direcciones = ({
    value: direccion,
    elementos,
    type,
    onChange: setDireccion,
    options: { setMessageSnackbar, resetCoords, resetDireccion, setElementos },
}) => {
    const [loading, setLoading] = useState(0);

    /** Recupera los elementos fijos (luminarias y semáforos) */
    const loadElementos = (abortController) => {
        try {
            setLoading((l) => l+1);
            const layer = type == 'LUMINARIA' ? 1 : 2;
            let url = new URL(`${api}/staticElements`);
            url.searchParams.set('layer', layer);
            url.searchParams.set('lat', direccion?.latitud);
            url.searchParams.set('lng', direccion?.longitud);
            url.searchParams.set('cantidadmaxima', 100);
            fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json' },
                signal: abortController.signal
            })
                .then((r) => r.json())
                .then((page) => {
                    setElementos(page);
                    setLoading((l) => l-1);
                })
                .catch((err) => {
                    setMessageSnackbar(err.message);
                    setLoading((l) => l-1);
                });
        } catch (err) {
            setMessageSnackbar(err.message);
            setLoading((l) => l-1);
        }
    };

    // Después de validar la dirección hay que cargar las luminarias y los semaforos (si corresponde)
    useEffect(() => {
        const abortController = new AbortController();  
        if ('SEMAFORO, LUMINARIA'.includes(type) && direccion?.validada == true && elementos?.length == 0) {
            loadElementos(abortController);
        }
        if (!direccion?.validada) {
            setElementos([]);
        }
        return () => abortController.abort();
    }, [direccion?.validada]);


    const handleCambiarModo = (ev, modo) => {
        resetDireccion();
        setDireccion({
            modo: modo.props.value,
        });
    }

    // calle: {codigo: '', nombre: ''}
    const handleSetCalle = (calle) => {
        resetCoords();
        setDireccion({ calle });
    }

    // calleCorta: {codigo: '', nombre: ''}
    const handleSetCalleCorta = (calleCorta) => {
        resetCoords();
        setDireccion({ calleCorta });
    }

    const handleText = (fldName) => (ev) => {
        if (fldName == 'altura') {
            if (direccion.calle.codigo == '') {
                resetDireccion();
                setDireccion({ altura: ev.target.value });
            } else {
                resetCoords();
                setDireccion({ altura: ev.target.value });
            }
        } else {
            setDireccion({ [fldName]: ev.target.value });
        }
    }

    const validarDireccion = () => {
        const abortController = new AbortController();
        const getGeoRef = () => {
            return new Promise((resolve, reject) => {
                let url = new URL(`${api}/direccion`);
                url.searchParams.set('streetCode', direccion.calle.codigo);
                url.searchParams.set('streetNumber', direccion.altura);
                url.searchParams.set('streetCodeCrossing', direccion.calleCorta.codigo);
                try {
                    fetch(url, { signal: abortController.signal })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.lat == 0 && data.lng == 0) {
                                if (direccion.modo == 'CALLE-ALTURA') {
                                    setDireccion({
                                        error: { calle: true, altura: true, calleCorta: false },
                                        validada: false,
                                        zoom: 12
                                    });
                                    reject(new Error('La dirección ingresada no es válida, la combinacion de calle y altura no existe.'));
                                } else {
                                    setDireccion({
                                        error: { calle: true, altura: false, calleCorta: true },
                                        validada: false,
                                        zoom: 12
                                    });
                                    reject(new Error('La dirección ingresada no es válida, las calles no se cortan.'));
                                }
                            } else {
                                setDireccion({
                                    latitud: parseFloat(data.lat),
                                    longitud: parseFloat(data.lng),
                                    error: { calle: false, altura: false, calleCorta: false },
                                    zoom: 17,
                                    validada: true,
                                });
                                resolve({
                                    latitud: parseFloat(data.lat),
                                    longitud: parseFloat(data.lng),
                                });
                            }
                        })
                        .catch(err => { throw err });
                } catch (err) {
                    reject(new Error('No se puede hacer la georeferencia. ' + err.message));
                }
            });
        };

        /**
         * Recupera la zonificación segun la georeferencia, el tercer parametro dice en que campo del state se guarda el resultado.
         * El state se actualiza llamando a setDireccion(...)
         * La respuesta es una promesa, que resuelve con el string del resultado 
         * @param {object} coords 
         * @param {number} layer 
         * @param {string} destField 
         * @return {Promise<string>}
         */
        const getZonificacion = (coords, layer, destField) => {
            return new Promise((resolve, reject) => {
                let url = new URL(`${api}/zonificacion`);
                url.searchParams.set('lat', coords.latitud);
                url.searchParams.set('lng', coords.longitud);
                url.searchParams.set('layer', layer);
                fetch(url, { signal: abortController.signal })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data?.descripcion != '') {
                            setDireccion({
                                [destField]: data.descripcion,
                            });
                            resolve(data.descripcion);
                        }
                        reject(new Error('No se puede determinar la zonificación.'));
                    })
                    .catch((err) => { reject(err) });
            });
        };

        return new Promise( (resolve, reject) => {
            try {
                if (loading) {
                    abortController.abort();
                }
                setLoading((l) => l+1);
                getGeoRef()
                    .then((coords) => {
                        if (type == 'DOMICILIO') {
                            const barrioPromise = getZonificacion(coords, ZONIFICACION.Barrios, 'barrio');
                            const zonaUrbanaPromise = getZonificacion(coords, ZONIFICACION.ZonaUrbana, 'zonaurbana'); // Reservas forestales
                            Promise.all([barrioPromise, zonaUrbanaPromise])
                                .then(() => {
                                    setLoading((l) => l-1);
                                    resolve(coords);
                                })
                                .catch((err) => {
                                    setLoading((l) => l-1);
                                    return reject(err);
                                });
                        } else {
                            setLoading((l) => l-1);
                            resolve(coords);
                        }
                    })
                    .catch((err) => {
                        setLoading((l) => l-1);
                        reject(err);
                    });
            } catch (err) {
                setLoading((l) => l-1);
                reject(err);
            }
        });
    };

    const handleValidarDireccion = async () => {
        try {
            if (direccion.modo == 'CALLE-ALTURA') {
                if (direccion.calle.codigo != '' && parseInt(direccion.altura) > 0) {
                    validarDireccion().catch((err) => {
                        setMessageSnackbar(err.message, 'error');
                    });
                } else {
                    throw new Error('Para validar la dirección debe primero completar una calle y una altura');
                }
            } else {
                if (direccion.calle.codigo != '' && direccion.calleCorta.codigo != '') {
                    validarDireccion().catch((err) => {
                        setMessageSnackbar(err.message, 'error');
                    });
                } else {
                    throw new Error('Para validar la dirección debe primero completar una calle y la calle que corta');
                }
            }
        } catch (err) {
            setMessageSnackbar(err.message, 'error');
        }
    }

    const handleCambiarLugar = (campo) => (lugar) => {
        resetDireccion();
        setDireccion({
            [campo]: lugar?.nombre || '',
            validada: lugar ? true : false,
        });
    }


    /**
     * Tipos de georeferencia posibles: LUMINARIA, SEMAFORO, DOMICILIO, VILLA, PLAZA, CEMENTERIO, ORGAN.PUBLICO, COLECTIVO
     */
    return (
        <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "middle", gap: "10px" }}>
            {'LUMINARIA, SEMAFORO, DOMICILIO'.includes(type) &&
                <DireccionDomicilio
                    type={type}
                    direccion={direccion}
                    onSetText={handleText}
                    onSetCalle={handleSetCalle}
                    onSetCalleCorta={handleSetCalleCorta}
                    onCambiarModo={handleCambiarModo}
                    setMessageSnackbar={setMessageSnackbar}
                />
            }

            {type == 'VILLA' &&
                <DireccionVilla
                    direccion={direccion}
                    onSetText={handleText}
                    onCambiarLugar={handleCambiarLugar}
                />
            }

            {type == 'PLAZA' &&
                <DireccionPlaza
                    direccion={direccion}
                    onCambiarLugar={handleCambiarLugar}
                />
            }

            {type == 'PLAYA' &&
                <DireccionPlaya
                    direccion={direccion}
                    onCambiarLugar={handleCambiarLugar}
                />
            }

            {type == 'CEMENTERIO' &&
                <DireccionCementerio
                    direccion={direccion}
                    onCambiarLugar={handleCambiarLugar}
                    onSetText={handleText}
                />
            }

            {type == 'ORGAN.PUBLICO' &&
                <DireccionOrgPublico
                    direccion={direccion}
                    onCambiarLugar={handleCambiarLugar}
                    onSetText={handleText}
                />
            }

            {type == 'COLECTIVO' &&
                <DireccionColectivo
                    direccion={direccion}
                    onSetText={handleText}
                />
            }


            <div style={{ flex: "1 1 180px", lineHeight: "60px", textAlign: "center" }}>
                {loading==0 && !direccion.validada && type != 'COLECTIVO' &&
                    <Button
                        variant="contained"
                        style={{ backgroundColor: "rgb(0, 166, 204)" }}
                        onClick={handleValidarDireccion}>
                        Validar dirección
                    </Button>
                }
                {loading>0 &&
                    <CircularProgress style={{ height: "30px", width: "30px" }} />
                }
            </div>

            {type != 'COLECTIVO' &&
                <Mapa
                    style={{ flex: "0 0 100%" }}
                    lat={direccion.latitud}
                    lng={direccion.longitud}
                    zoomLevel={direccion.zoom}
                    marker={direccion.validada && 'DOMICILIO, PLAZA, PLAYA, CEMENTERIO, ORGAN.PUBLICO'.includes(type)}
                    elementIcon={type}
                    elements={elementos}
                    onChange={setDireccion}
                    value={direccion}
                />}
        </Box>

    );
}

Direcciones.propTypes = {
    value: PropTypes.object.isRequired,
    elementos: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.object.isRequired,
};

export default Direcciones;
